import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table } from 'antd';
import { CartesianGrid, PieChart, Pie, Cell, AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, BarChart, Bar, LineChart, Line } from 'recharts';


const CartesianChart = ({
  resultSet,
  children,
  ChartComponent
}) => <div style = {{height:'100%', paddingBottom:30}}>
  <ResponsiveContainer width="100%" >
    <ChartComponent data={resultSet.chartPivot()}>
      <XAxis dataKey="x" />
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend />
      <Tooltip />
    </ChartComponent>
  </ResponsiveContainer>
  </div>

const colors = ['#FF6492', '#141446', '#7A77FF'];

const stackedChartData = resultSet => {
  const data = resultSet.pivot().map(({
    xValues,
    yValuesArray
  }) => yValuesArray.map(([yValues, m]) => ({
    x: resultSet.axisValuesString(xValues, ', '),
    color: resultSet.axisValuesString(yValues, ', '),
    measure: m && Number.parseFloat(m)
  }))).reduce((a, b) => a.concat(b), []);
  return data;
};

const TypeToChartComponent = {
  line: ({
    resultSet
  }) => {
    return <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
        {resultSet.seriesNames().map((series, i) => <Line key={series.key} stackId="a" dataKey={series.key} name={series.title} stroke={colors[i]} />)}
      </CartesianChart>;
  },
  bar: ({
    resultSet
  }) => {
    return <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
        {resultSet.seriesNames().map((series, i) => <Bar key={series.key} stackId="a" dataKey={series.key} name={series.title} fill={colors[i]} />)}
      </CartesianChart>;
  },
  area: ({
    resultSet
  }) => {
    return <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
        {resultSet.seriesNames().map((series, i) => <Area key={series.key} stackId="a" dataKey={series.key} name={series.title} stroke={colors[i]} fill={colors[i]} />)}
      </CartesianChart>
  },
  pie: ({
    resultSet
  }) => {
    return (
    <div style = {{height:'100%', paddingBottom:50}}>
      <ResponsiveContainer width="100%" >
          <PieChart>
            <Pie isAnimationActive={false} data={resultSet.chartPivot()} nameKey="x" dataKey={resultSet.seriesNames()[0].key} fill="#8884d8">
              {resultSet.chartPivot().map((e, index) => <Cell key={index} fill={colors[index % colors.length]} />)}
            </Pie>
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      )
  },

  number({
    resultSet
  }) {
    return <Row type="flex" justify="center" align="middle" style={{
      // height: '100%'
      fontSize:'2vw'
    }}>
        <Col>
          {resultSet.seriesNames().map(s => <Statistic value={resultSet.totalRow()[s.key]} />)}
        </Col>
      </Row>;
  },

  table({
    resultSet,
    pivotConfig
  }) {
    return <Table pagination={true} style= {{height:'90%', overflow:'auto'}} columns={resultSet.tableColumns(pivotConfig)} dataSource={resultSet.tablePivot(pivotConfig)} />;
  }

};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent).map(key => ({
  [key]: React.memo(TypeToChartComponent[key])
})).reduce((a, b) => ({ ...a,
  ...b
}));

const renderChart = Component => ({
  resultSet,
  error,
  pivotConfig
}) => resultSet && <Component resultSet={resultSet} pivotConfig={pivotConfig} /> || error && error.toString() || <Spin />;

const ChartRenderer = ({
  vizState
}) => {
  const {
    query,
    chartType,
    pivotConfig
  } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  if(component){
    return component && renderChart(component)({ ...renderProps,
      pivotConfig
    });
  }else{
    return <div style = {{height:'100mv',margin:'auto', textAlign:'center'}}> 
      Error: Dashboard Data Incorrect. Please edit to a proper format.
    </div>
  }
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null
};
export default ChartRenderer;